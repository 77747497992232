<template>
  <section class="section-content section-content--games">
    <div class="container">
      <div class="games-content">
        <h1 class="games-title">EXPLORE GAMES</h1>
        <div class="games-tabs-container">
            <IsotopeGrid />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import IsotopeGrid from "./Partial/IsotopeGrid.vue";

export default {
  name: "Games",
  components: {
    IsotopeGrid,
  },
};
</script>
<style scoped>
.section-content--games {
  background-color: black;
  padding-bottom: 20px;
}

.games-title {
  font-family: Proxy 9;
  font-size: 65px;
  font-weight: 400;
  line-height: 79px;
  letter-spacing: 0em;
  color: white;
}



.games-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 384px) {
  .games-title {
    font-size: 45px;
  }
}
</style>
