<template>
  <div class="layout-default">
    <Header />
    <slot/>
    <Footer />
  </div>
</template>

<script>
import Header from "./partial/Header.vue";
import Footer from "./partial/Footer.vue";

export default {
   components: {
    Header,
    Footer
  },
}
</script>
<style scoped>
.layout-default{
  margin:0;
  padding:0
}
</style>