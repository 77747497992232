<template>
  <header class="header-section">
    <div class="container">
      <div class="header-section__inner">
        <div class="logo"><a href="#"> <img src="@/assets/images/logo.png" /> </a></div>
        <div class="navigation">
          <nav class="nav-bar" id="navId">
            <ul>
              <li><router-link to="/">Home</router-link></li>
              <li><a href="/degen-wheel">Wheel</a></li>
              <li><a href="/degen-colors">Colors</a></li>
            </ul>
            <div class="connect-btn__responsive"><button @click="toggleWalletConnection" class="connect-btn">{{wallet.isConnected ? 'Disconnect':'Connect'}}</button></div>
          </nav>
        </div>

        <div class="header-connect">
          <button class="connect-btn" @click="toggleWalletConnection">{{wallet.isConnected ? 'Disconnect':'Connect'}}</button>
        </div>

        <span class="hamburger-btn" @click="toggleMenu"
          ><em class="fa fa-bars"></em
        ></span>
      </div>
    </div>
  </header>
</template>
<script>
import {phantomWallet} from '@/solana.js'
export default {
  components: {},
  data(){
    return {
      wallet:phantomWallet
    }
  },  
  methods: {
    toggleMenu() {
      let element = document.getElementById("navId");
      element.classList.toggle("responsive");
    },
    toggleWalletConnection(){
      if(this.wallet.isConnected){
        this.wallet.disconnect();
      }
      else{
        this.wallet.connect();
      }
    }
  },
};
</script>
<style scoped>
.header-section {
  background-color: black;
  padding: 15px 0;
  color: white;
}

.header-section__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
}

.hamburger-btn {
  display: none;
}

.nav-bar ul {
  list-style: none;
  margin: 0;
}

.nav-bar ul > li {
  display: inline-block;
  margin: 0 25px;
}

.nav-bar ul > li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.nav-bar ul > li a.router-link-exact-active {
  color: #f78e19;
}

.connect-btn {
  background-color: orange;
  outline: none;
  font-weight: 700;
  border-radius: 22px;
  width: 177px;
  height: 44px;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.connect-btn__responsive {
  display: none;
}

.connect-btn:focus {
  outline: none;
  box-shadow: none;
}

.connect-btn:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px orange;
  color: #000;
}

@media only screen and (max-width: 991px) {
  .hamburger-btn {
    display: block;
  }

  .header-connect {
    display: none;
  }

  .nav-bar {
    display: none;
    right: -100%;
    transition: all 2s ease-in-out;
  }

  .connect-btn__responsive {
    display: block;
    padding: 15px 0;
  }

  .nav-bar.responsive {
    position: fixed;
    display: block;
    top: 100px;
    right: 10%;
    transition: right 2s ease-in-out;
    background-color: rgb(95, 95, 95);
    z-index: 99;
    width: 250px;
    max-width: 100vw;
  }

  .nav-bar.responsive ul > li {
    display: block;
    padding: 2px;
    margin: 5px;
    background-color: rgb(51, 51, 51);
  }
  
}
</style>
