// const WARRIOR_WALLET_ID = "6bUdoSxo72W9dnYhCqZkiH1EteVUaagJ4KNCi9h6D5FA";
const WARRIOR_WALLET_ID = "6J9MP2NRAMFXiNep2yePn7wAm5m4ERVrYmieZSYsQYS";
const LAMPORTS_PER_SOL = 1000000000;
let NETWORK = "devnet";// this will be replaced by firebase api call
import Vue from 'vue';
import * as solanaWeb3 from '@solana/web3.js';
import './main.js';


class PhantomWallet {
  constructor() {
    this.publicKey = null;
    this.provider = null;
    this.lastTransactionId = null;
    this.isConnected = false;
    this.confirmed = false;
    this.clusterApiUrl = solanaWeb3.clusterApiUrl(NETWORK);
  }
  async connect() {
    /* open phantom wallet if user has installed the browser extension*/
    if (this.isPhantomWalletFound()) {
      var res = await window.solana.connect();
      this.isConnected = true;
      this.provider = window.solana;
      this.publicKey = res.publicKey.toString();
      return true;
    } else {
      handleUnexpendedError('Phantom wallet not found');
      return false;
    }
  }

  isPhantomWalletFound() {
    return window.solana != null && window.solana.isPhantom;
  }

  async disconnect() {
    if (this.isPhantomWalletFound()) {
      window.solana.disconnect();
      this.isConnected = false;
      this.publicKey = null;
    } else {
      handleUnexpendedError('Phantom wallet not found');
    }
  }

  reset() {
    this.confirmed = false;
    this.lastTransactionId = null;
  }

  async makeTransaction(selectedArray) {
    /* if there is any values reset them */
    this.lastTransactionId = null;
    this.confirmed = false;

    /*validate parameter */
    if (!Array.isArray(selectedArray) || selectedArray.length == 0) return handleUnexpendedError('Please add your bet');

    /* if not connected, try to connect wallet */
    if (this.publicKey == null) await this.connect();

    /* return if wallet not connected */
    if (this.publicKey == null) return handleUnexpendedError('Public key not found');

    /* configure values for transaction */
    const lamPorts = Number(getSum(selectedArray,'amount'));
    const transactionFee = Number((3 / 100) * lamPorts);
    const sol = Number(transactionFee + lamPorts).toFixed(4);

    /* call transaction function */
    return await this.createPhantomTransaction(
      sol * LAMPORTS_PER_SOL
    );
  }

  /* private function */
  async createPhantomTransaction(
    lamports
  ) {
    const connection = new solanaWeb3.Connection(this.clusterApiUrl, "confirmed");
    let transaction = await this.createTransferTransaction(connection, this.provider, WARRIOR_WALLET_ID, lamports);

    if (transaction == null) return handleUnexpendedError('Transaction provider error');
    let signed = null;

    try {
      signed = await this.provider.signAndSendTransaction(transaction);
    }
    catch (err) {
      return handleUnexpendedError(err.message);
    }

    if (signed == null) {
      return handleUnexpendedError('Transaction signing process failed');
    }
    else {
      const { signature } = signed;
      try {
        await connection.confirmTransaction(signature);
        return this.handleTransactionConfirmed(signature);
      } catch (err) {
        //may have error: "Transaction was not confirmed in 30.04 seconds. It is unknown if it succeeded or failed. Check signrature ... using the Solana Explorer or CLI"
        // console.error("createPhantomTransaction: handled transacErr:", err);
        let formattedMessage;
        console.log(err);
        if (err.message.startsWith("Transaction was not confirmed in")) {
          formattedMessage = "Transaction confirmation timeout";
        } else {
          formattedMessage = "unknown error\nconsole for more info";
        }
        return handleUnexpendedError(formattedMessage);
      }
    }
  }

  confirmTransaction(signature) {
    if (this.lastTransactionId == signature) {
      this.confirmed = true;
    }
  }

  handleTransactionConfirmed(signature) {
    this.lastTransactionId = signature;
    return { success: true, signature: signature, provider: this.provider, publicKey: this.publicKey };
  }

  /* private function */
  async createTransferTransaction(connection, provider, toPubKey, lamports) {
    let transaction = new solanaWeb3.Transaction().add(
      solanaWeb3.SystemProgram.transfer({
        fromPubkey: provider.publicKey,
        toPubkey: new solanaWeb3.PublicKey(toPubKey),
        lamports: lamports
      })
    );
    transaction.feePayer = provider.publicKey;
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  }
}

function handleUnexpendedError(message) {
  Vue.$toast.error(message);
  return { error: true };
}

/* private function */
function getSum(array, key) {
  let sum = 0;
  array.forEach(element => {
      sum += Number(element[key]);
  });
  return sum;
}

const phantomWallet = new PhantomWallet()
export { phantomWallet };