import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {io} from 'socket.io-client';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueToast from 'vue-toast-notification';

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export const EventBus = new Vue();


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// const socketUrl = 'https://backend.degenwarrior.xyz';
const socketUrl = 'http://localhost:3001';
const SocketInstance = io(socketUrl,{
  transports: ["websocket"]
});

Vue.prototype.$socket = SocketInstance;
// Vue.use(SocketInstance)


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
