<template>
  <section class="countdown" :style="{ display: displayStyle }">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress__line progress-bar-animated"
        role="progressbar"
        aria-label="Success striped example"
        :style="{ width: percentage }"
        aria-valuenow="100"
        id="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span v-if="waiting" class="progress__text"
          >Waiting for first bet...</span
        >
        <span v-else class="progress__text"
          >Time Remains For Next Spin : {{ timerCount }} seconds</span
        >
      </div>
    </div>
    <!-- <p class="wallet_text">Wallet ID : {{wallet.publicKey}}</p> -->

  </section>
</template>
<script>
import { EventBus } from "@/main.js";
import {phantomWallet} from '@/solana.js'
import {startPlay} from "@/assets.js";

export default {
  data() {
    return {
      wallet:phantomWallet,
      maxTime: 0,
      timerCount: 0,
      waiting:true,
    };
  },
  computed: {
    percentage() {
      return (this.timerCount / this.maxTime) * 100 + "%";
    },
    displayStyle() {
      // if (this.timerCount > 0) {
        return "initial";
      // } else {
      //   return "none";
      // }
    },
  },
  mounted(){
    EventBus.$on("setTimer", (timeObject) => {
      this.timerCount = timeObject.time;
      this.maxTime = timeObject.maxTime;
      this.waiting = timeObject.waiting;
    });
    EventBus.$on("resetTimer", () => {
      this.timerCount = 0;
    });
    EventBus.$on("reset-all", (event) => {
      this.timerCount = this.maxTime;
    });
  },

  watch: {
    // timerCount: {
    //   handler(value) {
    //     if (value > 0) {
    //       setTimeout(() => {
    //         this.timerCount--;
    //       }, 1000);
    //     }
    //   },
    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
};
</script>
<style scoped>
.progress__text {
  position: absolute;
    display: block;
    width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight:600;
  color: rgb(255, 255, 255);
}

.progress__line {
  background-color: rgb(199, 133, 10);
}

.wallet_text{
  margin-left: auto;
  color:white;
  font-weight: 600;
  color: rgb(199, 133, 10);
}

.progress,
.progress__line {
  position: relative;
  height: 30px;
  border-radius: 0 20px 20px 0;
}

.progress {
  background-color: #605f5d5e;
}
</style>
