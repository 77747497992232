<template>
  <div>
    <div class="games-tabs">
      <ul>
        <li v-for="(val, key) in option.getFilterData">
          <span
            class="tab"
            :class="[key === filterOption ? 'is-checked' : '']"
            @click="filter(key)"
          >
            {{ key }}
          </span>
        </li>
      </ul>
    </div>
    <div class="grid-wrapper">
    <isotope
      ref="cpt"
      id="root_isotope"
      class="isoDefault"
      :list="list"
      :options="option"
      @filter="filterOption = arguments[0]"
    >
      <div
      class="game_container"
        v-for="(element, index) in list"
        @click="selected = element"
        :key="index"
      >
        <GameCard 
        :game="element" />
      </div>
    </isotope>
    </div>
  </div>
</template>
<script>
import isotope from "vueisotope";
import GameCard from "./GameCard.vue";

export default {
  components: {
    isotope,
    GameCard
  },
  data() {
    return {
      list: [
        {
          name: "Degen Wheel",
          category: "Luck",
          route:"degen-wheel"
        },
        {
          name: "Instant Lotto",
          category: "Brain",
          route:"home"
        }
      ],
      selected: null,
      filterOption: "All",
      option: {
        getSortData: {
          id: "id",
        },
        sortBy: "id",
        getFilterData: {
          All: function () {
            return true;
          },
          Brain: function (el) {
            return el.category === "Brain";
          },
          Luck: function (el) {
            return el.category === "Luck";
          },
        },
      },
    };
  },

  methods: {
    filter: function (key) {
      this.$refs.cpt.filter(key);
    },
  },
};
</script>
<style scoped>
.isoDefault {
  margin-bottom: 50px;
}

.isoDefault>.item {
  /* background-color: #eee; */
  padding: 5px;
  width:250px;
  height: 250px;
  box-sizing: border-box;
  font-family: monospace;
  color: #333;
}


.is-checked {
  text-decoration: none;
  position: relative;
}

.is-checked:after {
  position: absolute;
  content: "";
  height: 2px;
  /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
  bottom: -4px;

  /****** 
       optional values below 
       ******/

  /* center - (optional) use with adjusting width   */
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 60%;
  background: orange;

  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tab {
    color: white;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

/* optional hover classes used with anmiation */
.tab:after {
  position: absolute;
  content: "";
  height: 2px;
  /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
  bottom: -4px;

  /****** 
       optional values below 
       ******/

  /* center - (optional) use with adjusting width   */
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 50%;

  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tab:hover:after {
  width: 80%;
  background: orange;
}

.isoDefault {
  min-height: 210px;
}

.games-tabs ul {
  list-style: none;
}

.games-tabs ul > li {
  display: inline-block;
  margin: 0 15px;
}
</style>
