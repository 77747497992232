<template>
  <footer class="footer">
      <div class="footer-upper">
        <div class="container">
          <div class="footer-upper__content">
            <div class="footer-upper__logo">
              <a href="#">
                <img
                  class="footer-logo"
                  src="@/assets/images/logo-inverted.png"
                />
              </a>
            </div>
            <div class="footer-upper__icons">
              <a href="#">
                <img src="@/assets/images/twitter.png" />
              </a>
              <a href="#">
                <img src="@/assets/images/mask.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-lower">
        <div class="container">
          <p class="footer-lower__text">
            &copy; DegenWarrior 2021-{{year}}. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
</template>
<script>
export default {
computed:{
  year(){
    return new Date().getFullYear();
  }
}
}
</script>
<style scoped>
.footer {
  background-color: black;
}

.footer-upper {
  background-color: #f78e19;
  padding: 10px 0;
}

.footer-upper__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-upper__icons a {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
}

.footer-lower__text {
  color: white;
  margin: 0;
}

.footer-logo {
}
</style>
