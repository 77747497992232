import spinSound from '@/assets/audio/spin.wav';
import winSound from '@/assets/audio/win.wav';
import loseSound from '@/assets/audio/lose.wav';
import betSound from '@/assets/audio/bet.wav';
import confirmSound from '@/assets/audio/confirm.wav';
import secondsSound from '@/assets/audio/seconds.wav';

let spin = new Audio();
spin.src = spinSound;

let win = new Audio();
win.src = winSound;

let lose = new Audio();
lose.src = loseSound;

let bet = new Audio();
bet.src = betSound;

let confirm = new Audio();
confirm.src = confirmSound;

let seconds = new Audio();
seconds.src = secondsSound;

const sounds = {
    'spin':spin,
    'win':win,
    'lose':lose,
    'bet':bet,
    'confirm':confirm,
    'seconds':seconds,
};

export function stopAllSounds() {
    document.querySelectorAll('audio').forEach(el => el.pause());
}

export function startPlay(sound){
    sounds[sound].currentTime = 0;
    sounds[sound].play();
}
