<template>
  <div class="wheel_section">
    <div class="wheel_wrapper">
      <span class="arrow"></span>
        <CanvasWheel />
    </div>
  </div>
</template>
<style scoped>
.wheel_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.arrow {
  position: relative;
  top: 0;
  left: 2px;
  color: rgb(230, 9, 9);
  height: 50px;
  /* z-index: 5; */
}

.arrow::before {
  content: "\1F817";
  font-size: 50px;
}
</style>
<script>
import CanvasWheel from "./CanvasWheel.vue";
export default {
  name: "WheelSection",
  components: {
    CanvasWheel}
};
</script>
