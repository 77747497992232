import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/Index.vue'
import DegenWheel from '../views/Wheel/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/degen-colors',
    name: 'degen-colors',
    component: HomeView
  },
  {
    path: '/degen-wheel',
    name: 'degen-wheel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/DegenWheel.vue')
    component: DegenWheel
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
