<template>
  <div
    id="wheel-container"
    :style="{ width: wheelRadius + 'px', height: wheelRadius + 'px' }"
    class="wheel-container"
  >
    <canvas id="myCanvas" width="480" height="480"></canvas>
    <img
      src="@/assets/images/logo_mini.png"
      style="display: none"
      alt="logo mini"
      id="logo-mini"
    />
  </div>
</template>
<style scoped>
.wheel-marker {
  left: 50%;
}

.wheel-container {
  background-color: #ccc;
  border-radius: 50%;
  border: 2px solid rgb(11, 125, 255);
  position: relative;
  overflow: hidden;
  transition: all 10s;
  transition-timing-function: cubic-bezier(0.01, 0.01, 0.05, 0.99);
}

.wheel-container div {
  position: absolute;
  /* transform: translateX(-50%); */
  transform-origin: bottom;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: rgb(10, 101, 248);
  left: 100px;
}
</style>
<script>
import { EventBus } from "@/main.js";
import {startPlay} from "@/assets.js";

export default {
  name: "CanvasWheel",
  data() {
    return {
      number: null,
      context: null,
      centerX: 0,
      centerY: 0,
      wheelRadius: 700,
      current: 0,
      sections: [],
      sectorRadian: 0,
      sectionDegrees: 0,
    };
  },
  computed: {
    sectorAngle() {
      return Math.round(360 / this.sections.length);
    },
    getHeight() {
      return "242px";
    },
    getWidth() {
      return "282px";
    },
  },
  created() {},
  mounted() {
    EventBus.$on("sectionsChanged", (data) => {
      this.sections = data.sections;
      this.initializeWheel();
    });

    EventBus.$on("startSpin", (data) => {
      this.current = data.value;
      this.number = data.number;
      this.spinWheel(data.spinDuration);
    });

    EventBus.$on("reset-all", (event) => {
      this.current = 0;
      this.resetWheel();
    });

    let img = document.getElementById("logo-mini");
    img.onload = this.drawLogoOnMiddle(img);
  },
  methods: {
    drawCurvedText(context, str, i, centerX, centerY, sectorRadian, radius) {
      const angle = sectorRadian * i;

      context.save();
      context.translate(centerX, centerY);
      context.rotate(1 * angle + sectorRadian / 2);
      context.fillStyle = "white";

      if (str.length > 3) {
        for (let j = 0; j < str.length; j++) {
          context.save();
          const logoWidth = 100;
          const fontSize = Math.min((radius - logoWidth) / str.length, 80);
          context.translate(0, -1 * radius * 0.9 + fontSize * j);
          context.fillText(str[j], 0, 0);
          context.restore();
        }
      } else {
        context.font = "20px Arial";
        context.save();
        context.translate(0, -1 * radius * 0.9);
        context.fillText(str, 0, 0);
        context.restore();
      }

      context.restore();
    },

    drawSectors(
      context,
      section,
      centerX,
      centerY,
      radius,
      startAngle,
      endAngle
    ) {
      context.save();

      const color = section.color;
      context.beginPath();
      context.moveTo(centerX, centerY);
      context.arc(
        centerX,
        centerY,
        radius,
        startAngle - this.degreesToRadian(90),
        endAngle - this.degreesToRadian(90),
        false
      );
      context.fillStyle = color;
      context.fill();

      context.restore();
    },

    degreesToRadian(degrees) {
      return degrees * (Math.PI / 180);
    },

    initializeWheel() {
      var canvas = document.getElementById("myCanvas");
      canvas.width = this.wheelRadius;
      canvas.height = this.wheelRadius;
      this.context = canvas.getContext("2d");
      this.centerX = canvas.width / 2;
      this.centerY = canvas.height / 2;
      var radius = this.wheelRadius / 2;
      let startAngle = 0;

      this.sectionDegrees = 360 / this.sections.length;
      this.sectorRadian = (Math.PI / 180) * this.sectionDegrees;
      let endAngle = this.sectorRadian;

      this.context.font = "30pt Calibri";
      this.context.textAlign = "center";
      this.context.fillStyle = "yellow";
      this.context.strokeStyle = "blue";
      this.context.lineWidth = 4;

      for (let i = 0; i < this.sections.length; i++) {
        this.drawSectors(
          this.context,
          this.sections[i],
          this.centerX,
          this.centerY,
          radius,
          startAngle,
          endAngle
        );
        startAngle += this.sectorRadian;
        endAngle += this.sectorRadian;
      }

      for (let i = 0; i < this.sections.length; i++) {
        this.drawCurvedText(
          this.context,
          this.sections[i].label,
          i,
          this.centerX,
          this.centerY,
          this.sectorRadian,
          radius
        );
      }

      let img = document.getElementById("logo-mini");
      this.drawLogoOnMiddle(img);
    },

    drawLogoOnMiddle(img) {
      if (this.context == null) return;
      this.context.drawImage(
        img,
        this.centerX - img.width / 2,
        this.centerY - img.height / 2
      );
    },

    // getRandomColor() {
    //   const colors = [
    //     "orange",
    //     "yellow",
    //     "red",
    //     "blue",
    //     "green",
    //     "rgb(180, 42, 222)",
    //     "rgb(213, 243, 20)",
    //     "rgb(20, 176, 243)",
    //     "rgb(30, 247, 22)",
    //   ];
    //   let number = Math.round(Math.random() * colors.length) - 1;
    //   return colors[number];
    // },
    // getStyles(index) {
    //   const color = this.getRandomColor();
    //   return {
    //     backgroundColor: color,
    //     transform: "rotate(" + index * this.sectorAngle + "deg)",
    //     clipPath: "polygon(100% 0, 50% 100%, 0 0)",
    //     height: this.getHeight,
    //     width: this.getWidth,
    //   };
    // },
    spinWheel(spinDuration) {
      // // const random = Math.round(Math.random() * 10) / 10;
      // const random = 0;
      // this.current +=
      //   360 * random + 360 * 5 - this.sectionDegrees * Math.random();
      startPlay('spin')
      let container = document.querySelector(".wheel-container");
      container.style.transitionDuration = "10s";
      container.style.transform = "rotate(" + this.current + "deg)";

      setTimeout(() => {
        EventBus.$emit("finalizeWinner", {
          number: this.number,
        });
      }, Number((spinDuration+2)*1000));
    },
    resetWheel() {
      let container = document.querySelector(".wheel-container");
      container.style.transitionDuration = "0s";
      container.style.transform = "rotate(0deg)";
      setTimeout(() => {
        container.style.transitionDuration = "10s";
      }, 1000);
    },
  },
};
</script>
