<template>
  <div>
      <div class="card">
        <div class="card_content">
          <p class="game_name">{{ game.name }}</p>
        <button @click="redirectToGame(game)" class="play_btn">Play</button>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["game"],
  methods:{
    redirectToGame(game){
      return this.$router.push({ name: game.route })
    }
  }
};
</script>
<style scoped>
.card {
  position:relative;
  height: 250px;
  width: 100%;
  border-radius: 13px;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.469),
      rgba(0, 0, 0, 0.469) 100%
    ),
    url("@/assets/images/degenWheel.png");
  /* background-image:url('@/assets/images/bg.png'); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.card_content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
        right: 0;
    left: 0;
    bottom: 10px;
}

.card_content:hover .play_btn{
  transition: all .5s;
 background-color: #07d04a;
    transform: scale(1.1);
}

.card_content:hover .play_btn{
  transition: all .5s;
 background-color: #07d04a;
    transform: scale(1.1);
}

.game_name {
  position: relative;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  top: 50%;
}

.play_btn{
    color: white;
    width: 150px;
    height: 50px;
    background-color: #00BF41;
    border-radius: 20px;
    outline: none;
    position: relative;
    cursor: pointer;
  top: 60%;

}
</style>
