<template>
  <section  v-if="show" class="alert-section">
    <div class="blur"></div>
    <div class="alert">
      <div class="alert__box">
        <h1 v-if="win" class="alert__title win">Congratulations!</h1>
        <h1 v-else class="alert__title loose">Oops!</h1>
        <div v-if="win" class="won-alert">
          <img class="alert__img win" src="@/assets/images/win.png" />
          <p class="alert_selected">WINNING NUMBER IS : {{number}}</p>
          <p class="alert_description">You Won {{amount}} SOL</p>
        </div>
        <div v-else class="loose-alert">
            <img class="alert__img loose" src="@/assets/images/loose.png" />
            <p class="alert_selected">WINNING NUMBER IS : {{number}}</p>
          <p class="alert_description loose">Try Again Now !</p>
        </div>
        <div class="button-box">
          <button @click="refresh" class="btn">
            {{ win ? "Got it" : "Try Again" }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { EventBus } from "@/main.js";
import {startPlay,stopAllSounds} from "@/assets.js";

export default {
  data() {
    return {
      show: false,
      amount:0,
      win: false,
      number: 0,
    };
  },
  mounted() {

    EventBus.$on("show-alert", (event) => {
      this.show = event.show;
      this.win = event.win;
      this.number = event.number;
      this.amount = event.amount;
      if(this.show){
        if(this.win){
        startPlay('win');
      }
      else{
        startPlay('lose');
      }
      }
      
    });
    EventBus.$on("reset-all", (event) => {
      this.refresh();
      stopAllSounds()
    });
  },
  methods: {
    refresh() {
      this.show = false;
      this.number = 0;
      this.win = false;
    },
  },
};
</script>
<style scoped>
.alert-section {
  z-index: 10;
  height: 100%;
}
.blur {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(23, 23, 23, 0.772);
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(23, 23, 23, 0.772);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.alert_selected{
    color: rgb(226, 226, 226);
    font-size: 30px;
    font-weight: 700;
    padding-top: 10px;
}

.alert__box {
  height: 50vh;
  width: 50vw;
  border: 2px white solid;
  border-radius: 20px;
}

.alert__img{
    width: 200px;
    height: 200px;
}

.alert__img.win {
  margin-top: 15px;
}

.alert__img.loose {
  margin-top: 35px;
}

.alert__title {
  font-weight: 700;
}

.alert__title.win {
  color: rgb(255, 251, 12);
}

.alert__title.loose {
  color: orange;
}

.alert_description {
  margin-top: 10px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 5px;
}

.alert_description.loose {
  margin-top: 10px;
}

.button-box {
  margin-top: 25px;
}

.btn {
  /* color: white; */
  font-weight: 600;
  background-color: orange;
  width: 200px;
}

.btn:focus {
  outline: none;
}
</style>
