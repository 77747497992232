<template>
  <layout-default>
    <main class="main-section">
      <div class="container">
        <CountDown class="upper-section__countdown" />
        <section class="upper-section">
          <WheelSection class="upper-section__wheel" />
          <FormSection class="upper-section__form" />
        </section>
        <section class="lower-section">
          <UserSection class="lower-section__user" />
        </section>
        <Alert />
      </div>
    </main>
  </layout-default>
</template>
<style scoped>
.main-section {
   background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4962359943977591) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("@/assets/images/bg.png");
  min-height: 100vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

}
.upper-section {
  display: grid;
  grid-template-columns: 2.8fr 1.2fr;
}

.upper-section__countdown {
}
.upper-section__wheel {
}

.upper-section__form {
}

.lower-section {
}

.lower-section__user{
  margin-top: 100px;
}
</style>

<script>
import Vue from 'vue';
import { EventBus } from "@/main.js";
import { phantomWallet } from "@/solana.js";
import LayoutDefault from "@/layouts/DefaultLayout.vue";
import WheelSection from "./Partial/WheelSection.vue";
import FormSection from "./Partial/FormSection.vue";
import UserSection from "./Partial/UserSection.vue";
import CountDown from "./Partial/CountDown.vue";
import Alert from "./Partial/Alert.vue";

export default {
  name: "DegenWheel",
  components: {
    LayoutDefault,
    WheelSection,
    CountDown,
    FormSection,
    UserSection,
    Alert
  },
  data() {
    return {
      socket: null,
    };
  },
  created() {

  },
  mounted() {
    this.$socket.emit("requestGame",{game:'DW'});

    this.$socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    this.$socket.on("initializeGame", (obj) => {
      EventBus.$emit("reset-all");
      EventBus.$emit("resetTransactionConfirmation");
      EventBus.$emit("sectionsChanged",obj);
      EventBus.$emit("betListUpdated",obj);
      EventBus.$emit("setTimer",obj.time);
    });

    this.$socket.on("disableBet", () => {
      EventBus.$emit("bet-disable");
    });
    
    this.$socket.on("transactionConfirmed", (data) => {
      if(phantomWallet.lastTransactionId == data.signature){
        EventBus.$emit("bet-disable");
        phantomWallet.confirmTransaction(data.signature);
        Vue.$toast.success('Transaction completed');
      }
    });
   
    this.$socket.on("transactionError", (data) => {
      Vue.$toast.error(data.message);
    });

    this.$socket.on("betListUpdated", (list) => {
      EventBus.$emit("betListUpdated",list);
    });

    this.$socket.on("setTimer", (time) => {
      EventBus.$emit("setTimer",time);
    });

    this.$socket.on("startSpin", (data) => {
      EventBus.$emit("startSpin",data);
      EventBus.$emit("resetTimer");
    });
    
  },
};
</script>
