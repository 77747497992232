<template>
<layout-default>
   <main class="main-section">
      <Hero />
      <Games />
    </main>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/DefaultLayout.vue';
import Hero from './Sections/Hero.vue';
import Games from './Sections/Games.vue';

export default {
  name: "Home",
   components: {
    LayoutDefault,
    Hero,
    Games
  },
  data() {
    return {
    };
  },
};
</script>

