<template>
  <div class="user_section">
    <h4 class="title">Live Bets</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Wallet ID</th>
            <th class="text-center" scope="col">Selections</th>
            <th class="text-center" cope="col">Bet Amount</th>
            <th class="text-center" cope="col">Win Number</th>
            <th class="text-center" cope="col">Win Amount</th>
            <th class="text-center" cope="col">Status</th>
          </tr>
        </thead>
        <tbody v-if="bets.length > 0">
          <tr  v-for="(bet, index) in bets" :key="index">
            <th scope="row">{{ bet.walletId }}</th>
            <td>{{ bet.selections.map(value => value.number).toString() }}</td>
            <td>{{ bet.amount }} SOL</td>
            <td>{{ bet.winNumber }}</td>
            <td>{{ bet.winAmount }}</td>
            <td>
              <span v-if="bet.status == 2" class="badge badge-danger"
                >Looser</span
              >
              <span v-else-if="bet.status == 1" class="badge badge-success"
                >Winner</span
              >
              <span v-else class="badge badge-secondary">Pending</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr >
            <td colspan="4" >No bets yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

export default {
  name: "UserSection",
  data() {
    return {
      bets: [
        // {
        //   walletId: "927jh34972492424",
        //   status: 0,
        //   amount: 0,
        //   selections: [1, 3, 4, 8, 9],
        // },
      ],
    };
  },
  mounted() {
    EventBus.$on("betListUpdated", (data) => {
      this.bets = data.list ?? [];
    });
  },
};
</script>
<style scoped>
h4 {
  color: #f78e19;
}
.user_section {
}

.table {
  color: white;
}

table > thead > tr > th {
  color: #f78e19;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tbody > tr > td {
  border-top: 1px solid rgb(97, 97, 97);
  border-bottom: 1px solid rgb(97, 97, 97);
}

.title {
  position: relative;
}

/* .title:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -2px;

  margin: 0 auto;
  left: 0;
  right: 0;
  width: 5%;
  background: #f78e19;
} */

.table {
  margin-top: 15px;
}

.table-responsive{
  margin-bottom: 0;
}
</style>
