<template>
  <section class="form-section">
    <div class="form-container">
      <div class="form align-items-center">
        <form @submit.prevent="formSubmit">


          <div class="win-section my-1" v-if="disabled == true">
            <!-- <em class="fa fa-trophy fa-2xl win-section__icon"></em> -->
            <h3   class="win-section__title">Winning Section is</h3>
            <h3 v-if="showWin && winSection != null" class="win-section__value">
              {{ winSection.label }}
            </h3>
            <p v-else class="win-section__processing">
              Processing ...
            </p>
          </div>

          <div  v-if="disabled != true" class="my-1">
            <p class="step_text">
              STEP 1 &nbsp;
              <i class="fas fa-hand-point-down step_icon"></i> &nbsp;
              <span class="step_description">Enter you bet amount</span>
            </p>
            <input
              :disabled="disabled"
              type="number"
              step="0.001"
              min="0.05"
              class="form-control input-style"
              id="inlineFormInputName"
              placeholder="Betting Amount"
              v-model="form.amount"
            />
            <span class="error-message text-danger">{{ errors.amount }}</span>
          </div>
          

          <div v-if="!disabled" class="my-1">
            <p class="step_text">
              STEP 2 &nbsp;
              <i class="fas fa-hand-point-down step_icon"></i> &nbsp;
              <span class="step_description">Guess your selection <br/> (Multiple selections are allowed)</span>
            </p>
            <div class="selection_wrapper">
              <button
                :disabled="disabled"
                :style="getBtnStyles(section)"
                :class="{ activeBtn: containsObject(section) }"
                v-for="(section, index) in betActiveNumbers"
                :key="index"
                type="button"
                @click="addSelectedList(section)"
                class="btn selection-btn"
              >
                {{ section.label }}
              </button>
            </div>
          </div>
          <div class="">
            <span class="error-message text-danger">{{ errors.grid }}</span>
          </div>

          <!-- <div class="col-auto add_btn_wrapper my-1">
            <p class="step_text">STEP 3 &nbsp; <i class="fas fa-hand-point-down step_icon"></i> &nbsp; <span class="step_description">Add selections to list</span></p>
            <button :disabled="disabled" type="submit" class="add-btn btn">
              Add
            </button>
          </div> -->
        </form>
      </div>
      <hr />
      <div class="info-section">
        <p class="info-section__title">Your Bets</p>
        <p
          v-for="(selectedValue, selectedIndex) in displayValues"
          :key="selectedIndex"
          :style="{ backgroundColor: selectedValue.color }"
          class="info-section__numbers"
        >
          {{ selectedValue.label }} <span class="fas fa-arrow-right"></span>
          {{ selectedValue.amount }} SOL
        </p>
        <div class="info-section__total-section">
          <p class="info-section__total-title">Total Bet Amount</p>
          <p class="info-section__total-amount">{{ totalAmount }} SOL</p>
        </div>
        <hr />

        <div v-if="!disabled" class="col-auto submit-wrapper">
          <span class="error-message text-danger">{{ errors.confirm }}</span>
          <p class="step_text">
            STEP 3 &nbsp;
            <i class="fas fa-hand-point-down step_icon"></i> &nbsp;
            <span class="step_description">Confirm your transaction</span>
          </p>

          <button
            :disabled="disabled || selectedList.length == 0 || transferring"
            type="button"
            @click="confirmTransaction"
            class="btn submit-btn"
            :class="[
              transferring ? 'submit-btn__transfer' : 'submit-btn__confirmed',
            ]"
          >
            <span v-if="transferring">Transferring....</span>
            <span v-else> {{ confirmationText }}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.form-section {
  display: flex;
  flex-direction: column;
}

.activeBtn,
.activeBtn:hover,
.activeBtn:focus {
  background-color: rgb(155, 108, 0);
  color: white;
  transform: scale(0.97);
}

.form-container {
  position: relative;
  background-color: black;
  border: 1px Gray solid;
  border-radius: 10px;
  top: 5%;
  padding: 25px 0;
}

hr {
  margin-top: 40px;
  border: 2px solid Gray;
}

form {
  padding: 0 10px;
}

input,
/* select, */
button {
  border-radius: 10px;
}

input
/* select  */ {
  margin-bottom: 15px;
}

.step_text {
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.step_icon {
  color: rgb(255, 251, 14);
}

.step_description {
  color: rgba(194, 194, 194, 0.415);
}

.info-section {
  color: white;
}

.input-style,
.input-style:focus,
.input-style:disabled {
  background-color: black;
  color: white;
  border: 1px #f78e19 solid;
  border-radius: 10px;
}

input {
  line-height: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select::-ms-expand {
  display: none;
}

input::placeholder,
.selectionBox.emptySelection {
  color: rgba(255, 255, 255, 0.665);
}

button,
button:hover,
button:focus {
  background-color: #f78e19;
  color: rgb(0, 0, 0);
  outline: none;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
}

.error-message {
  position: relative;
}

.btn {
  border: none;
  cursor: pointer;
  width: 100%;
}

.btn:disabled {
  background-color: #949493;
  cursor: not-allowed;
}

.add-btn,
.add-btn:hover,
.add-btn:focus {
  padding: 6px 15px;
  color: white;
  background-color: #05d10c;
}

.submit-btn__transfer,
.submit-btn__transfer:hover,
.submit-btn__transfer:focus {
  background-color: #047730;
  padding: 6px 10px;
  cursor: not-allowed;
}

.submit-btn,
.submit-btn:focus {
  padding: 6px 10px;
  color: white;
  background-color: #009c22;
}

.submit-btn:hover {
  transition: 1s all;
  transform: scale(1.05);
  color: white;
  background-color: #037b1d;
}

.selection-title {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.selection-btn {
  margin: 5px;
  width: 40%;
}

.selection-btn:focus {
  background-color: rgb(255, 217, 0);
}

.selection_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.add_btn_wrapper {
  padding-top: 15px;
}

.info-section__title {
  padding: 0px;
  margin: 0px;
}

.info-section__numbers {
  padding: 2px;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 10px 5px 10px;
  border-radius: 10px;
}

.info-section__total-section {
  background-color: #008bee;
  border-radius: 10px;
  margin: 0 5px;
}

.info-section__total-title {
  padding-top: 10px;
  margin: 0;
}

.info-section__total-amount {
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.submit-wrapper {
  margin-top: 20px;
}

.win-section {
  color: white;
}

.win-section__icon {
  color: rgb(238, 254, 8);
  margin-bottom: 15px;
}

.win-section__value {
  color: rgb(238, 254, 8);
}

.win-section__processing {
  color: rgb(255, 255, 255);
  font-weight: 800;
}

@media only screen and (max-width: 575px) {
  .error-message {
    position: relative;
  }

  .submit-wrapper {
    width: 100%;
  }

  .submit-btn {
    width: 100%;
    display: block;
  }
}
</style>
<script>
import { EventBus } from "@/main.js";
import { phantomWallet } from "@/solana.js";
import {startPlay} from "@/assets.js";

export default {
  data() {
    return {
      wallet: phantomWallet,
      transferring: false,
      showWin: false,
      winNumber: null,
      sections: [],
      disabled: false,
      winSection:null,
      selectedList: [],
      form: {
        amount: null,
        guess_id: null,
      },
      errors: {
        amount: "",
        guess: "",
        confirm: "",
        grid: "",
      },
    };
  },
  mounted() {
    EventBus.$on("sectionsChanged", (data) => {
      this.sections = data.sections;
    });
    EventBus.$on("bet-disable", (event) => {
      this.disabled = true;
      this.form.amount = null;
    });
    EventBus.$on("reset-all", (event) => {
      this.clearValidationErrors();
      this.wallet.reset();
      this.selectedList = [];
      this.disabled = false;
      this.showWin = false;
    });
    EventBus.$on("finalizeWinner", (event) => {
      this.showWin = true;
      const winResult = this.isNumberWin(event.number);
      const amount = this.getWinAmount(event.number, winResult);
      EventBus.$emit("show-alert", {
        show: this.selectedList.length > 0,
        win: winResult.win,
        amount: amount,
        number: event.number,
      });
    });

    EventBus.$on("startSpin", (data) => {
      this.winNumber = data.number;
      this.winSection = data.winSection;
    });
  },
  computed: {
    confirmationText() {
      if (this.wallet.confirmed) {
        return "Confirmed";
      } else {
        return "Confirm";
      }
    },
    betActiveNumbers() {
      /* get active items */
      const activeArray = this.sections.filter((item) => {
        return item.active;
      });

      /* remove duplicate items */
      const uniqueArray = activeArray.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.number === value.number)
      );

      /*sort array */
      return uniqueArray.sort((a, b) => a.number - b.number);
    },
    displayValues() {
      let array = [];
      this.selectedList.forEach((item) => {
        const selection = this.getSelectionById(item.id);
        const obj = {
          amount: item.amount,
          number: selection.number,
          label: selection.label,
          color: selection.color,
        };
        array.push(obj);
      });
      return array;
    },
    totalAmount() {
      let sum = 0;

      this.selectedList.forEach((element) => {
        sum += parseFloat(element.amount);
      });
      return Math.round(sum * 1000) / 1000;
    },
  },
  methods: {
    getBtnStyles(section) {
      const contains = this.containsObject(section);
      const opacity = contains ? 0.5 : 1;
      const border = contains ? "4px orange double" : "none";
      return {
        backgroundColor: section.color,
        opacity: opacity,
        border: border,
      };
    },
    addSelectedList(section) {
      this.form.guess_id = section.id;
      this.formSubmit();
    },
    isNumberWin(number) {
      for (let i = 0; i < this.selectedList.length; i++) {
        const section = this.getSelectionById(this.selectedList[i].id);
        if (section.number == number) {
          return { win: true, section: this.selectedList[i] };
        }
      }
      return { win: false };
    },
    getWinAmount(number, winResult) {
      if (!winResult.win) return 0;
      return Number(number * Number(winResult.section.amount)).toFixed(4);
    },
    containsObject(obj) {
      for (let i = 0; i < this.selectedList.length; i++) {
        if (this.selectedList[i].id == obj.id) {
          return true;
        }
      }
      return false;
    },

    getSelectionById(id) {
      return this.sections.find((x) => x.id === id);
    },
    formSubmit() {
      if (!this.validateForm()) return false;
      startPlay('bet');
      const index = this.selectedList.findIndex(
        (x) => x.id === this.form.guess_id
      );
      if (index == -1) {
        this.selectedList.push({
          id: this.form.guess_id,
          amount: this.form.amount,
        });
      } else {
        this.selectedList[index].amount = this.form.amount;
      }
      this.clearForm();
    },
    validateForm() {
      this.clearValidationErrors();
      if (this.form.amount == null || this.form.amount <= 0) {
        return this.throwError("amount", "Please input your bet amount");
      }
      if (this.form.amount < 0.05) {
        return this.throwError("amount", "Minimum bet is 0.05 SOL");
      }
      if (this.form.guess_id == null) {
        return this.throwError("grid", "Please select your guess number");
      }
      return true;
    },
    throwError(key, message) {
      this.errors[key] = message;
      return false;
    },
    clearForm() {
      this.form.guess_id = null;
    },
    clearValidationErrors() {
      this.errors.confirm = "";
      this.errors.guess = "";
      this.errors.grid = "";
      this.errors.amount = "";
    },
    async confirmTransaction() {
      this.transferring = true;
      startPlay('confirm');
      const transactionResult = await this.wallet.makeTransaction(
        this.selectedList
      );

      if (transactionResult.success) {
        const data = {
          signature: transactionResult.signature,
          publicKey: transactionResult.publicKey,
          selectedList: this.selectedList,
        };
        this.$socket.emit("placeBet", data);
      } else {
        //transaction error message will be displayed it self.no need to handle here.
      }
      this.transferring = false;
    },
  },
};
</script>
